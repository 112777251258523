import { Button } from "@material-ui/core";
import {
  Check,
  EmailOutlined,
  KeyboardArrowDown,
  LocationOnOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import ServiceCard from "../components/ui/Services/ServiceCard";
import { Images, getSingleImageBySrc } from "../utils/findImage";

import homepageData from "../content/pages/homepage.json";
import contactInfo from "../content/settings/contact_info.json";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";

interface HomepageProps {
  data: {
    allImages: Images;
    projectGallery: Images;
  };
}

const Homepage: React.FC<HomepageProps> = ({ data }) => {
  const homepageHero = getSingleImageBySrc(
    homepageData.topSection.mainImage.src,
    data.allImages
  );

  const aboutImage = getSingleImageBySrc(
    homepageData.about.image.src,
    data.allImages
  );

  const benefitsImage = getSingleImageBySrc(
    homepageData.benefits.image.src,
    data.allImages
  );

  return (
    <Layout>
      <SEO
        title={homepageData.seo.seoTitle}
        description={homepageData.seo.metaDescription}
        slug="home"
      />

      <section className="relative p-0">
        <div className="absolute inset-0 z-10 w-full h-full bg-black bg-opacity-40 " />

        <div>
          {typeof homepageHero !== "undefined" ? (
            <GatsbyImage
              image={homepageHero}
              alt={homepageData.topSection.mainImage.alt}
              className="h-[70vh] z-0"
            />
          ) : null}
        </div>
        <div className="absolute left-[50%] top-[50%] transform translate-y-[-60%] translate-x-[-50%] text-center z-20 container text-4xl">
          <h2 className="sm:text-xl text-lg font-medium text-white mb-1">
            {homepageData.topSection.subHeadline}
          </h2>
          <h1 className="sm:text-5xl text-3xl text-white max-w-screen-sm mx-auto mb-0">
            {homepageData.topSection.headline}
          </h1>
        </div>
        <div className="absolute z-20 w-full text-center text-white bottom-10 ">
          <a href="#about">
            <KeyboardArrowDown
              className="relative transform transition-transform hover:translate-y-1"
              fontSize="large"
            />
          </a>
        </div>
      </section>

      <section id="about">
        <div className="container">
          <div className="flex flex-wrap lg:flex-row-reverse items-center lg:justify-between">
            <div className="lg:w-6/12 sm:w-8/12 w-full max-w-screen-sm mb-5 lg:mb-0">
              {typeof aboutImage !== "undefined" ? (
                <GatsbyImage
                  image={aboutImage}
                  alt={homepageData.about.image.alt}
                />
              ) : null}
            </div>
            <div className="w-full lg:w-5/12 max-w-screen-sm">
              <SectionIntro
                subHeading={homepageData.about.subHeadline}
                heading={homepageData.about.headline}
              />
              {homepageData.about.description.split(/\n/g).map(
                (paragraph, index) =>
                  !!paragraph && (
                    <p key={`about-p-${index}`} className="mb-3 last:mb-0">
                      {paragraph}
                    </p>
                  )
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div className="container">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-8 justify-items-center">
            <div className="col-span-full">
              <SectionIntro
                subHeading={homepageData.services.subHeadline}
                heading={homepageData.services.headline}
                className="text-center mb-4"
              >
                <p className="max-w-md mx-auto">
                  {homepageData.services.description}
                </p>
              </SectionIntro>
            </div>
            {homepageData.services.services.map((service) => (
              <ServiceCard
                key={service.serviceName}
                title={service.serviceName}
                image={getSingleImageBySrc(
                  service.image.src
                    .replace("/images/homepage/", "")
                    .replace(".jpg", ""),
                  data.allImages
                )}
                alt={service.image.alt}
                to={`/services/${service.linkedService}/`}
              >
                {service.serviceDescription}
              </ServiceCard>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex flex-wrap items-center lg:justify-between">
            <div className="lg:w-6/12 sm:w-8/12 w-full max-w-screen-sm mb-5 lg:mb-0">
              {typeof benefitsImage !== "undefined" ? (
                <GatsbyImage
                  image={benefitsImage}
                  alt={homepageData.benefits.image.alt}
                />
              ) : null}
            </div>
            <div className="w-full lg:w-5/12 max-w-screen-sm">
              <SectionIntro
                subHeading={homepageData.benefits.subHeadline}
                heading={homepageData.benefits.headline}
              />
              {homepageData.benefits.description.split(/\n/g).map(
                (paragraph, index) =>
                  !!paragraph && (
                    <p key={`benefits-p-${index}`} className="mb-3 last:mb-0">
                      {paragraph}
                    </p>
                  )
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-900 py-20">
        <div className="container">
          <div className="flex flex-wrap sm:justify-between justify-center items-center">
            <h3 className="text-2xl text-white sm:mb-0 text-center sm:text-left mb-4">
              Ready To Discuss Your Project?
            </h3>
            <Button
              component={Link}
              to="/contact/"
              className="rounded-none font-semibold"
              variant="contained"
              color="primary"
              size="large"
              disableElevation
            >
              Get a quote
            </Button>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ">
            <SectionIntro
              subHeading="Our work"
              heading="Explore our recent projects"
              className="col-span-full text-center mb-8 justify-self-center"
            >
              <p className="max-w-lg mx-auto">
                No matter how big or small, we approach every project with the
                same attention to detail.
              </p>
            </SectionIntro>
          </div>
          <ProjectGallery>
            {data.projectGallery.nodes.map((item, index) => (
              <div key={`portfolio item-${index}`} className="mb-4">
                <GatsbyImage
                  image={item.childImageSharp.gatsbyImageData}
                  alt={homepageData.projects[0].images[index].alt}
                />
              </div>
            ))}
          </ProjectGallery>
        </div>
      </section>

      <section className="bg-gray-50">
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>
              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address.street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address.city}</span>
                    <span>{contactInfo.address.zipCode}</span>
                  </li>
                  <li>{contactInfo.address.state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone}`}
                    >
                      {formatPhoneNumber(contactInfo.phone)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query($projectGallery: [String!], $allImages: [String!]) {
    allImages: allFile(filter: { base: { in: $allImages } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
      }
    }
    projectGallery: allFile(filter: { base: { in: $projectGallery } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
`;
